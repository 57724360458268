import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery.marquee/jquery.marquee.min';
import './hamburger';
import './gallery';

$(window).on('load', function() {
    $('.marquee').marquee({
        duration: 30000,
        direction: 'left',
        delayBeforeStart: 0,
        pauseOnHover: true,
        startVisible: true,
        duplicated: true,
        gap: 0
    });
});
